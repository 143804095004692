<template lang="">
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Autorizações</template>
        <template #content>
          <div>
            <DataTable
              class="p-datatable-sm"
              sortField="razaoSocial"
              :loading="loading"
              :sortOrder="1"
              :value="listAutorizacoes"
              stripedRows
              responsiveLayout="stack">
              <template #empty> Nenhuma consignataria encontrada. </template>
              <template #loading> Carregando. Por favor aguarde. </template>
              <Column
                field="consignatariaRazaoSocial"
                header="Razão Social"></Column>
              <Column field="cnpj" header="CNPJ"></Column>
              <Column field="ativo" header="Autorização">
                <template #body="{ data }">
                  <Button
                    label="Gerir Autorização"
                    icon="pi pi-check-circle"
                    :class="mudarCorBotao(data)"
                    @click="abrirModal(data)" />
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </Card>
    </div>

    <autorizacao-portabilidade-modal
      :visible.sync="visible"
      :autorizacaoPortabilidadeProps="autorizacaoPortabilidade" />
  </div>
</template>
<script>
import AutorizcaoPortabilidadeService from '@/service/AutorizacaoPortabilidadeService'
import AutorizacaoPortabilidadeModal from '@/views/servidor/autorizacaoPortabilidade/AutorizacaoPortabilidadeModal'
import ServidorService from '@/service/ServidorService'

export default {
  components: {
    'autorizacao-portabilidade-modal': AutorizacaoPortabilidadeModal,
  },

  data() {
    return {
      visible: false,
      loading: true,
      listAutorizacoes: [],
      autorizacaoPortabilidade: {},
      servidor: {},
    }
  },
  created() {
    this.autorizacaoPortabilidadeService = new AutorizcaoPortabilidadeService(
      this.$http,
    )
    this.servidorService = new ServidorService(this.$http)
  },
  mounted() {
    this.carregarServidor()
  },

  methods: {
    carregarServidor() {
      this.servidorService
        .getDadosServidor()
        .then((res) => {
          this.servidor = res
          this.carregarAutorizacoes(this.servidor.id)
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    carregarAutorizacoes(servidorId) {
      this.autorizacaoPortabilidadeService
        .listarConsignatariasComAutorizacao(servidorId)
        .then((res) => {
          this.listAutorizacoes = res
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    abrirModal(data) {
      this.autorizacaoPortabilidade = data
      this.visible = true
    },

    salvarAutorizacao(data) {
      this.atribuirDados(data)
      this.autorizacaoPortabilidadeService
        .salvar(this.autorizacaoPortabilidade)
        .then(() => {
          this.carregarAutorizacoes()
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    mudarCorBotao(data) {
      if (!data.ativo) {
        return 'p-button-submit md:col-12'
      }

      return 'p-button-danger md:col-12'
    },
  },
}
</script>
<style lang=""></style>
