<template lang="">
  <Dialog
    :header="exibirHeader()"
    :visible="visible"
    :closeOnEscape="!saving"
    :closable="!saving"
    :contentStyle="{ overflow: 'visible' }"
    :modal="true"
    @update:visible="fecharDialog()"
    @show="onShow()"
    @hide="onHide()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="field grid">
            <label class="col-fixed" style="width: 300px"
              >Permitir Visualizar Consignações</label
            >
            <div class="col">
              <span class="p-inputgroup-addon">
                <Checkbox
                  id="binary"
                  v-model="autorizacaoPortabilidade.ativo"
                  :binary="true"
                  @click="confirm()" />
              </span>
            </div>
          </div>
          <div v-if="autorizacaoPortabilidade.ativo" class="field grid">
            <label class="col-fixed" style="width: 300px"
              >Permitir Prazo e/ou Valor da Parcela Maior</label
            >
            <div class="col">
              <span class="p-inputgroup-addon">
                <Checkbox
                  id="binary"
                  v-model="autorizacaoPortabilidade.autorizacaoValorParcela"
                  :binary="true"
                  @click="confirm2()" />
              </span>
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fecharDialog()" />
      <Button :disabled="saving" autofocus @click="salvarAutorizacao()">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>

    <ConfirmDialog></ConfirmDialog>
  </Dialog>
</template>
<script>
import AutorizcaoPortabilidadeService from '@/service/AutorizacaoPortabilidadeService'
export default {
  props: {
    visible: { type: Boolean, required: true },
    autorizacaoPortabilidadeProps: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      saving: false,
      submitted: false,
      autorizacaoPortabilidade: {},
      autorizacaoValorParcela: false,
      ativo: false,
    }
  },

  created() {
    this.autorizacaoPortabilidadeService = new AutorizcaoPortabilidadeService(
      this.$http,
    )
  },
  mounted() {},

  methods: {
    salvarAutorizacao() {
      this.autorizacaoPortabilidadeService
        .salvar(this.autorizacaoPortabilidade)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Autorização salva com sucesso!',
            life: 10000,
          })
          this.fecharDialog()
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    confirm() {
      if (this.autorizacaoPortabilidade.ativo === false) {
        this.$confirm.require({
          message:
            'Ao confirmar, você autoriza o consignatário realizar a portabilidade e consultar todas as consignações registradas. Gostaria de prosseguir com essa autorização?',
          header: 'Confirmação',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.autorizacaoPortabilidade.ativo = true
            this.$confirm.close()
          },
          reject: () => {
            this.autorizacaoPortabilidade.ativo = false
            this.autorizacaoPortabilidade.autorizacaoValorParcela = false
            this.$confirm.close()
          },
        })
      } else {
        this.autorizacaoPortabilidade.ativo = false
        this.autorizacaoPortabilidade.autorizacaoValorParcela = false
      }
    },

    confirm2() {
      if (this.autorizacaoPortabilidade.autorizacaoValorParcela === false) {
        this.$confirm.require({
          message: `Nos termos do artigo 3°, parágrafo único, da resolução n 4.292, de 20/12/2013, do Conselho Monetário Nacional, autorizo a Consignatária proponente - ${this.autorizacaoPortabilidade.consignatariaRazaoSocial}, aumentar a quantidades de parcelas e valor da prestação da operação de crédito das consignações desta autorização, objeto da portabilidade, em razão do valor da prestação contratada de outras Consignatárias (Instituição Financeira) serem maiores do que os valores das prestações inicialmente contratada na Consignatária credora original.`,
          header: 'Confirmação',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.autorizacaoPortabilidade.autorizacaoValorParcela = true
            this.$confirm.close()
          },
          reject: () => {
            this.autorizacaoPortabilidade.autorizacaoValorParcela = false
            this.$confirm.close()
          },
        })
      } else {
        this.autorizacaoPortabilidade.autorizacaoValorParcela = false
      }
    },

    exibirHeader() {
      return 'Editar Autorizações do Servidor'
    },

    fecharDialog() {
      this.autorizacaoPortabilidade = {}
      this.$emit('update:visible', false)
    },

    onShow() {
      this.saving = false
      this.autorizacaoPortabilidade = this.autorizacaoPortabilidadeProps
    },
    onHide() {},
    exibirToastSuccess(message) {
      this.$toast.add({
        severity: 'success',
        summary: message,
        life: 10000,
      })
    },

    exibirToastError(err) {
      this.$toast.add({
        severity: 'error',
        summary: err.response.data.message,
        life: 10000,
      })
    },
  },
}
</script>
<style lang=""></style>
